'use client';

import { useToast } from '@/hammr-ui/hooks/use-toast';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/hammr-ui/components/toast';
import CheckboxCircleFill from '@/hammr-icons/CheckboxCircleFill';
import InformationFill from '@/hammr-icons/InformationFill';
import ErrorWarningFill from '@/hammr-icons/ErrorWarningFill';
import AlertFill from '@/hammr-icons/AlertFill';
import { Progress } from './progress';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        toastVariant,
        progress,
        ...props
      }) {
        return (
          <Toast className="rounded-xl border-soft-200 p-4" key={id} {...props}>
            <div className="flex">
              <div className="mr-3 self-start">
                {toastVariant === 'success' && (
                  <CheckboxCircleFill color="#1FC16B" />
                )}
                {toastVariant === 'info' && <InformationFill color="#335CFF" />}
                {toastVariant === 'error' && (
                  <ErrorWarningFill color="#FB3748" />
                )}
                {toastVariant === 'warning' && <AlertFill color="#EE6023" />}
              </div>
              <div className="grid gap-1">
                {title && (
                  <ToastTitle className="font-medium text-foreground">
                    {title}
                  </ToastTitle>
                )}
                {description && (
                  <ToastDescription className="text-sub-600">
                    {description}
                  </ToastDescription>
                )}
                {progress !== undefined && (
                  <div className="mt-2.5">
                    <Progress value={progress} />
                  </div>
                )}
              </div>
              {action}
              <ToastClose />
            </div>
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
