import { NextRouter } from 'next/router';

export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

type AnyObject = { [key: string]: any };

/**
 * compare two objects and return the differences
 * @param obj1 {object} - the first object to compare - usually the original
 * @param obj2 {object} - the second object to compare - usually the updated
 * @returns {object} - the differences between the two objects
 * @example diffObjects({a: 1, b: 2}, {a: 1, b: 3}) // {b: 3}
 * */
export const diffObjects = (obj1: AnyObject, obj2: AnyObject): AnyObject => {
  const result: AnyObject = {};

  // Iterate through the properties of obj2
  for (const key in obj2) {
    // If the property exists in both objects
    if (key in obj1) {
      // If the property values are objects, recursively compare them
      if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
        const nestedDiff = diffObjects(obj1[key], obj2[key]);
        if (Object.keys(nestedDiff).length > 0) {
          result[key] = nestedDiff;
        }
      } else if (Array.isArray(obj2[key])) {
        // If the property values are arrays, compare the stringified versions
        if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
          result[key] = obj2[key];
        }
      } else {
        // If the property values are primitives, compare them directly
        if (obj1[key] !== obj2[key]) {
          result[key] = obj2[key];
        }
      }
    } else {
      // If the property only exists in obj2, include it in the result
      result[key] = obj2[key];
    }
  }

  return result;
};

// Pixel GIF code adapted from https://stackoverflow.com/a/33919020/266535
const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

const triplet = (e1: number, e2: number, e3: number) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (r: number, g: number, b: number) => {
  return `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;
};

export const padZero = (num: number): string => {
  return num.toString().padStart(2, '0');
};

export const removeEmptyFields = (obj: AnyObject, filterFn?): AnyObject => {
  const filter = filterFn || ((entry) => entry !== null && entry !== undefined);
  const payloadEntries = Object.entries(obj)
    .map((entry) => {
      const [key, value] = entry;
      if (Boolean(value)) {
        return entry;
      }

      return null;
    })
    .filter(filter);

  return Object.fromEntries(payloadEntries);
};

export const setTabQuery = (router: NextRouter, tab: string) => {
  const newQuery = { ...router.query, tab: tab };
  router.replace(
    {
      pathname: router.pathname,
      query: newQuery as Record<any, any>,
    },
    '',
    { shallow: false }
  );
};
