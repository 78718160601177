import { handleNetworkError, logError, validateNetworkResponse } from 'utils/errorHandling';
import { defaultHammrHeaders } from 'utils/requestHelpers';

export async function hideChannel(channelUrl: string) {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/chat/${channelUrl}/hide`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
      },
    });

    await validateNetworkResponse(res);
  } catch (err) {
    logError(err);
    handleNetworkError(err);
  }
}

export async function unhideChannel(channelUrl: string) {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/chat/${channelUrl}/unhide`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
      },
    });

    await validateNetworkResponse(res);
  } catch (err) {
    logError(err);
    handleNetworkError(err);
  }
}

export async function createAnnouncement(message: string, receiverUserIds: number[]) {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/chat/announcement`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message,
        receiverUserIds,
      }),
    });

    await validateNetworkResponse(res);

    return res.json();
  } catch (err) {
    logError(err);
    handleNetworkError(err);
  }
}

export async function getAnnouncement(id: string) {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/chat/announcement/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
      },
    });

    await validateNetworkResponse(res);

    return res.json();
  } catch (err) {
    logError(err);
    handleNetworkError(err);
  }
}

export async function getToken(): Promise<{
  token: string;
  expires_at: number;
}> {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/chat/token`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...defaultHammrHeaders,
      },
    });

    await validateNetworkResponse(res);

    const { data } = await res.json();
    return data;
  } catch (err) {
    logError(err);
    handleNetworkError(err);
  }
}
